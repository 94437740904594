import "./src/shop-master/css/animate.css"
import "./src/shop-master/css/icomoon.css"
import "./src/shop-master/css/bootstrap.css"
import "./src/shop-master/css/flexslider.css"
import "./src/shop-master/css/style.css"

import { Auth } from 'aws-amplify'
import { setUser } from './src/services/auth'

export const onRouteUpdate = (state, page, pages) => {
  Auth.currentAuthenticatedUser()
  .then(user => {
    const userInfo = {
      ...user.attributes,
      username: user.username,
    }
  setUser(userInfo)
  })
  .catch(err => {
    window.localStorage.setItem('gatsbyUser', null)
  })
}